<form class="login-form" (submit)="onSubmit($event)">
  <dx-form [formData]="formData" [disabled]="loading">

        <dxi-item dataField="email"  editorType="dxTextBox" [editorOptions]="{ stylingMode: 'filled', placeholder: 'Email', mode: 'email' }">
            <dxi-validation-rule type="required" message="Email es requerido"></dxi-validation-rule>
            <dxi-validation-rule type="email" message="Email is invalid"></dxi-validation-rule>
            <dxo-label [visible]="false"></dxo-label>
        </dxi-item>

        <dxi-item dataField="password"   editorType="dxTextBox" [editorOptions]="{ stylingMode: 'filled', placeholder: 'Password', mode: 'password' }">
            <dxi-validation-rule type="required" message="Password es requerida"></dxi-validation-rule>
            <dxo-label [visible]="false"></dxo-label>
        </dxi-item>

        <!-- <dxi-item dataField="rememberMe" editorType="dxCheckBox" [editorOptions]="{ text: 'Recordarme ', wrapperAttr: { class: 'form-text' } }">
            <dxo-label [visible]="false"></dxo-label>
        </dxi-item> -->

    <dxi-item
      itemType="button"
      [buttonOptions]="buttonOptions"
      [template]="'signInTemplate'">
    </dxi-item>

    <dxi-item>
        <div class="link">
            <a routerLink="/reset-password">¿Olvidó la Clave?</a>
        </div>
    </dxi-item>

    <dxi-item>
      <div class='policy-info'>
        No esta registrado? <a routerLink="/create-account">Crear cuenta</a>
      </div>
    </dxi-item>

    <ng-container *dxTemplate="let item of 'signInTemplate'">
      <span class="dx-button-text">
        <ng-container *ngIf="loading; else notLoading">
          <dx-load-indicator width="24px" height="24px" [visible]="true"></dx-load-indicator>
        </ng-container>
        <ng-template #notLoading>Acceder</ng-template>
      </span>
    </ng-container>

  </dx-form>
</form>
