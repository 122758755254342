<form class="reset-password-form" (submit)="onSubmit($event)">
  <dx-form [formData]="formData" [disabled]="loading">

    <dxi-item dataField="email" editorType="dxTextBox"
      [editorOptions]="{ stylingMode: 'filled', placeholder: 'Email', mode: 'email' }">
      <dxi-validation-rule type="required" message="El Email es requerido"></dxi-validation-rule>
      <dxi-validation-rule type="email" message="El Email es invalido"></dxi-validation-rule>
      <dxo-label [visible]="false"></dxo-label>
    </dxi-item>


    <dxi-item
      itemType="button"
      [buttonOptions]="buttonOptions"
      [template]="'resetPasswordTemplate'">
    </dxi-item>
    <!-- <dxi-item itemType="button">
      <dxo-button-options width="100%" type="default" [useSubmitBehavior]="true" [template]="'resetPasswordTemplate'"
        [wrapperAttr]="{ class: 'submit-button' }">
      </dxo-button-options>
    </dxi-item> -->
    <dxi-item>
      <div class="login-link">
        Regresar al <a routerLink="/login-form">Login</a>
      </div>
    </dxi-item>

    <ng-container *dxTemplate="let item of 'resetPasswordTemplate'">
      <span class="dx-button-text">
        <ng-container *ngIf="loading; else notLoading">
          <dx-load-indicator width="24px" height="24px" [visible]="true"></dx-load-indicator>
        </ng-container>
        <ng-template #notLoading>Restablecer contraseña</ng-template>
      </span>
    </ng-container>

  </dx-form>
</form>
