import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { IApiUserAuthenticated } from '../../interfaces/IApiUserAuthenticated';
import { IApiUserToken } from '../../interfaces/IApiUserToken';
import { SegTusuarios, UserChangePassword, UserCreation, UserCredentials, UserModification, UserRegistration, ValidateUser } from '../../models/user-credentials';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { WebApiBaseService } from '../../web-api-base/web-api-base.service';
import { IEmailReset, IPassReset } from '../../models/passReset.model';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root'
})
export class AuthService extends WebApiBaseService {
  private userLocalStorage: string = 'currentUser';
  private rememberLocalStorage: string = 'remember';
  private timeoutRefreshToken: any;

  public currentUser: BehaviorSubject<IApiUserAuthenticated | null>;

  constructor(private http: HttpClient, private route: Router, private cookie:CookieService) {
    super();
    this.currentUser = new BehaviorSubject
      (
        JSON.parse(localStorage.getItem(this.userLocalStorage) || "null")
      );

    if (this.getUser != null && JSON.parse(localStorage.getItem(this.rememberLocalStorage) || "{}")) {
      this.setRefreshToken();
    }
  }

  get getUser(): IApiUserAuthenticated | null{
    return this.currentUser.value;
  }

  get loggedIn(): boolean {
    return !!this.currentUser.value;
  }

  get rememberSession(): boolean {
    return JSON.parse(localStorage.getItem(this.rememberLocalStorage) || "{}");
  }

  login(user: UserCredentials, remember: boolean): Observable<IApiUserAuthenticated> {
    
    return this.http.post<IApiUserAuthenticated>(`${this.baseUrl}Accounts/Authenticate`, user)
      .pipe(
        map(r => {
          if (remember) {
            this.setUserLocalStorage(r);
            this.setRememberLocalStorage(remember);
          }
          else {
            this.CleanUserLocalStorage();
            this.CleanRememberLocalStorage();
          }

          this.currentUser.next(r);
          this.route.navigateByUrl('Pedidos');
          this.setRefreshToken();
          return r;
        })
      );
  }

  // refreshToken(): void {

  //  let user = this.getUser;
  //  let a=user!.RefreshToken;
  //  this.cookie.set('refreshToken',a)
  //  const refreshToken:string = a ;

  //  const headers = new HttpHeaders({
  //   'refreshToken': refreshToken,
  //   'accept': 'text/plain',
  //   'Content-Type': 'application/json-patch+json'
  // });

  // const options = {
  //   headers: headers
  // };

  // this.http.post<any>(`${this.baseUrl}Accounts/refresh-token`, null, options)
  //   .subscribe(
  //     (r:any) => {
  //          this.setUserLocalStorage(r);
  //          this.currentUser.next(r);

  //          this.setRefreshToken();
  //         return r;
  //     }
  //   )

  // }

  refreshToken(): void {
    let user = this.getUser;
    let a = user!.RefreshToken;
    this.cookie.set('RefreshToken', a)
    const RefreshToken: string = a;
 
    const headers = new HttpHeaders({
     'refreshToken': RefreshToken,
     'accept': 'text/plain',
     'Content-Type': 'application/json-patch+json'
    });
 
    const options = {
     headers: headers
    };
 
    this.http.post<any>(`${this.baseUrl}Accounts/refresh-token`, null, options)
     .subscribe({
       next: (response: any) => {
        //  console.log('Respuesta completa del refresh:', response);
        //  console.log('Refresh token recibido:', response?.RefreshToken);
        //  console.log('Estado del usuario:', {
        //    token: response?.JwtToken,
        //    RefreshToken: response?.RefreshToken,
        //    role: response?.Role,
        //    // otros campos relevantes...
        //  });

         if (!response || !response.RefreshToken) {
           console.error('No se recibió un refresh token válido');
           this.handleRefreshError('No se recibió un refresh token válido');
           return;
         }

         this.setUserLocalStorage(response);
         this.currentUser.next(response);
         this.setRefreshToken();
       },
       error: (error) => {
         console.error('Error detallado:', {
           status: error.status,
           message: error.message,
           error: error.error,
           headers: error.headers?.keys()
         });
         this.handleRefreshError(error);
       }
     });
 }
  private handleRefreshError(error: any): void {
    // Limpiar tokens
    this.cookie.delete('RefreshToken');
    this.currentUser.next(null);
    
    // Redirigir al login u otra acción
    this.route.navigate(['/login']);
  }
  setUserLocalStorage(user: IApiUserAuthenticated): void {
    localStorage.setItem(this.userLocalStorage, JSON.stringify(user));
  }

  setRememberLocalStorage(remember: boolean): void {
    localStorage.setItem(this.rememberLocalStorage, JSON.stringify(remember));
  }

  CleanUserLocalStorage(): void {
    localStorage.removeItem(this.userLocalStorage);
  }

  CleanRememberLocalStorage(): void {
    localStorage.removeItem(this.rememberLocalStorage);
  }

  logout(): void {
    localStorage.removeItem(this.userLocalStorage);
    this.currentUser.next(null);

    if (this.timeoutRefreshToken != null) {
      clearTimeout(this.timeoutRefreshToken);

    }

    this.route.navigateByUrl('login');
  }

  setRefreshToken(): void {


    const min:number=1;
    const max:number=15;
    const intervalo=  Math.floor(Math.random() * (max - min + 1)) + min;

    this.timeoutRefreshToken = setTimeout(() => {
      this.refreshToken();
    }, intervalo*1000*60);
  }


  resetPassword(data: IEmailReset)  {
    return this.http.post<IPassReset>(`${this.baseUrl}Accounts/forgot-password`, data);

  }

  crearCuenta(userCreated: UserRegistration)  {

    return this.http.post<any>(`${this.baseUrl}Accounts/register`, userCreated);
    //return this.http.post<IApiUserAuthenticated>(`${this.baseUrl}security/add`, user);

  }

  modificarCuenta(usuario: UserModification) {
    let user = this.getUser

    return this.http.put<any>(`${this.baseUrl}Accounts/${user?.IdPjf}`, usuario);
  }


  changePassword(changePassDto: UserChangePassword)  {
    return this.http.post<IPassReset>(`${this.baseUrl}Accounts/reset-password`, changePassDto);

  }

   validateUserEmail(userToken: ValidateUser)  {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json-patch+json',
      'Accept': '*/*',
    });

    const body = {
      Token: userToken.Token,
    };
     return this.http.post<any>(`${this.baseUrl}Accounts/verify-email`, body, { headers });
  }


}
