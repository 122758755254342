<form [formGroup]="registrationForm" class="create-account-form" 
(keydown.enter)="$event.preventDefault(); onEnterKeyPressed()" (submit)="onSubmit($event)" autocomplete="off"   >

  <div class="dx-fieldset" *ngIf="isCreate()">
    <div class="dx-fieldset-header">Credenciales</div>
    <div class="dx-field">
      <div class="dx-field-label">Email</div>
      <div class="dx-field-value">
        <dx-text-box formControlName="email" autofill="off"   [inputAttr]="{ 'aria-label': 'Email' }">
          <dx-validator>
            <dxi-validation-rule type="required" message="El email es requerido"></dxi-validation-rule>
            <dxi-validation-rule type="email" message="El email es invalido"></dxi-validation-rule>
            <dxi-validation-rule type="async" message= "Este email ya esta siendo usado" [validationCallback]="asyncValidationEmail"></dxi-validation-rule>
          </dx-validator>
        </dx-text-box>

      </div>
    </div>
    <div class="dx-field" *ngIf="isCreate()">
      <div class="dx-field-label">Password</div>
      <div class="dx-field-value">
        <dx-text-box formControlName="password" [inputAttr]="{ 'aria-label': 'Password' }" autofill="off" [mode]="passwordMode"
          [(value)]="password" (onValueChanged)="onPasswordChanged()">
          <dxi-button name="password" location="after" [options]="passwordButton"></dxi-button>
          <dx-validator>
            <dxi-validation-rule type="required" message="La contraseña es requerida"></dxi-validation-rule>
          </dx-validator>
        </dx-text-box>
      </div>
    </div>
    <div class="dx-field" *ngIf="isCreate()">
      <div class="dx-field-label">Confirme Password</div>
      <div class="dx-field-value">
        <dx-text-box formControlName="confirmPassword" [(value)]="confirmPassword" [mode]="confirmPasswordMode"
          [inputAttr]="{ 'aria-label': 'Password' }">
          <dxi-button name="password" location="after" [options]="confirmPasswordButton"></dxi-button>
          <dx-validator #targetValidator>
            <dxi-validation-rule type="required" message="La confirmación de la contraseña es requerida"></dxi-validation-rule>
            <dxi-validation-rule type="compare" [comparisonTarget]="passwordComparison"
              message="Las contraseñas no coinciden"></dxi-validation-rule>
          </dx-validator>
        </dx-text-box>
      </div>
    </div>
  </div>


  <div class="dx-fieldset">
    <div class="dx-fieldset-header">Información personal</div>


    <div class="dx-field">
      <div class="dx-field-label">Nombre Fantasía</div>
      <div class="dx-field-value">
        <dx-text-box value="" formControlName="nombreFantasia" [inputAttr]="{ 'aria-label': 'Nombre Fantasia' }">
          <dx-validator>
            <dxi-validation-rule type="required" message="Nombre Fantasía es requerido"></dxi-validation-rule>
            <!-- <dxi-validation-rule type="pattern" [pattern]="namePattern"
              message="No se permite numeros en el nombre"></dxi-validation-rule>
            <dxi-validation-rule type="stringLength" [min]="2"
              message="El nombre debe tener al menos 2 digitos"></dxi-validation-rule> -->
          </dx-validator>
        </dx-text-box>
      </div>
    </div>

    <div class="dx-field">
      <div class="dx-field-label">Nombre</div>
      <div class="dx-field-value">
        <dx-text-box value="" formControlName="nombre" [inputAttr]="{ 'aria-label': 'Nombre' }">
          <dx-validator>
            <dxi-validation-rule type="required" message="Nombre es requerido"></dxi-validation-rule>
            <dxi-validation-rule type="pattern" [pattern]="namePattern"
              message="No se permite numeros en el nombre"></dxi-validation-rule>
            <dxi-validation-rule type="stringLength" [min]="2"
              message="El nombre debe tener al menos 2 digitos"></dxi-validation-rule>
          </dx-validator>
        </dx-text-box>
      </div>
    </div>


    <div class="dx-field">
      <div class="dx-field-label">Apellido</div>
      <div class="dx-field-value">
        <dx-text-box formControlName="apellido" value="" [inputAttr]="{ 'aria-label': 'Apellido' }">
          <dx-validator>
            <dxi-validation-rule type="required" message="Apellido es requerido"></dxi-validation-rule>
            <dxi-validation-rule type="pattern" [pattern]="namePattern"
              message="No se permite numeros en el apellido"></dxi-validation-rule>
            <dxi-validation-rule type="stringLength" [min]="2"
              message="El apellido debe tener al menos 2 digitos"></dxi-validation-rule>
          </dx-validator>
        </dx-text-box>
      </div>
    </div>


    <div class="dx-field">
      <div class="dx-field-label">Telefono Celular </div>

      <div style="display: flex;">
        <div style="flex: 1; max-width: 100px;">
          <dx-text-box
          #codigoPais
            id="codigoPais"
            [inputAttr]="{ 'aria-label': 'CodPais' }"
            value="+549"
            formControlName="codigoPais">
          </dx-text-box>
          <!-- [disabled]="true" -->
        </div>
        <div style="flex: 1; max-width: 100px;">
          <dx-number-box
            #codigoArea
            id="codigoArea"
            name="codigoArea"
            (onKeyDown)="onKeyDown($event)"
            maskInvalidMessage="El código de área tiene que tener entre 2 y 4 dígitos sin ceros delante"
            validationMessagePosition="left"
            formControlName="codigoArea">
          </dx-number-box>
        </div>
        <div style="flex: 3;">
          <dx-text-box [inputAttr]="{ 'aria-label': 'Phone' }"
            #telefono
            id="telefono"
            name="telefono"
            [mask]="mask"
            validationMessagePosition="left"
            formControlName="telefono">
          </dx-text-box>
        </div>
      </div>
    </div>
  </div>


  <div class="dx-fieldset">
    <div class="dx-fieldset-header">Información fiscal</div>

    <div class="dx-field">
      <div class="dx-field-label">Nro Documento / CUIT </div>
      <div class="dx-field-value">
        <dx-text-box   formControlName="nroDoc" value="" [inputAttr]="{ 'aria-label': 'Nro Documento' }">
          <dx-validator>
            <dxi-validation-rule type="required" message="El nro de documento es requerido"></dxi-validation-rule>
            <dxi-validation-rule  type="stringLength"
            [min]="8  " message="Minimo 8 digitos"></dxi-validation-rule>


            <!-- <dxi-validation-rule type="async" [message]=  mensajeCuit
            [validationCallback]="asyncValidationCuit"></dxi-validation-rule> -->
            <dxi-validation-rule type="async"  [validationCallback]="asyncValidationCuit"></dxi-validation-rule>
          </dx-validator>
        </dx-text-box>
        <span id="nroDocHint" class="dx-field-hint">{{mensajeCuit}}</span>
         
      </div>
    </div>

    <div class="dx-field" *ngIf="isUpdate()">
      <div class="dx-field-label">Razón Social</div>
      <div class="dx-field-value">
        <dx-text-box value="" formControlName="razonSocial" [disabled]="true" [inputAttr]="{ 'aria-label': 'Razon Social' }">
        </dx-text-box>
      </div>
    </div>

    <div class="dx-field" *ngIf="isUpdate()">
      <div class="dx-field-label">Tipo Doc</div>
      <div class="dx-field-value">
        <dx-text-box formControlName="tipoDoc"  [disabled]="true" [inputAttr]="{ 'aria-label': 'Tipo de Documento' }">        </dx-text-box>
      </div>
    </div>


<!--
    <div class="dx-field">
      <div class="dx-field-label">Condicion fiscal</div>
      <div class="dx-field-value">
        <dx-text-box formControlName="condicionFiscal"  [disabled]="true" [inputAttr]="{ 'aria-label': 'Condicion Fiscal' }">        </dx-text-box>
      </div>
    </div>


    <div class="dx-field" >
      <div class="dx-field-label">Personeria</div>
      <div class="dx-field-value">
        <dx-text-box formControlName="personeria"  [disabled]="true" [inputAttr]="{ 'aria-label': 'Personeria' }">        </dx-text-box>

      </div>
    </div> -->

    <div class="dx-field">
      <div class="dx-field-label">Relación</div>
      <div class="dx-field-value">
        <dx-select-box [dataSource]="roles" displayExpr="Nombre" valueExpr="Id" validationMessagePosition="left"
          [inputAttr]="{ 'aria-label': 'Rol' }" formControlName="rol" [readOnly]="isUpdate()" [(ngModel)]="rol">
          <dx-validator>
            <dxi-validation-rule type="required" message="La relacion es requerida"></dxi-validation-rule>
          </dx-validator>
        </dx-select-box>
      </div>
    </div>
  </div>


  <div class="dx-fieldset">
    <div class="dx-fieldset-header">Direccion de Facturacion</div>
    <div class="dx-field">
      <div class="dx-field-label">Pais</div>
      <div class="dx-field-value">
        <dx-select-box id="pais" [dataSource]="paises" displayExpr="Nombre" valueExpr="IdPais" validationMessagePosition="left"
          [inputAttr]="{ 'aria-label': 'Pais' }" (onValueChanged)="obtenerProvinciaxPais($event)" formControlName="pais"
          [searchEnabled]="true">
          <dx-validator>
            <dxi-validation-rule type="required" message="El pais es requerido"></dxi-validation-rule>
          </dx-validator>
        </dx-select-box>
      </div>
    </div>
    <div class="dx-field">
      <div class="dx-field-label">Provincia</div>
      <div class="dx-field-value">
        <dx-select-box id="provincia" [dataSource]="provinciasxPais" displayExpr="Nombre" valueExpr="IdProvincia"
          validationMessagePosition="left" [inputAttr]="{ 'aria-label': 'Provincia' }" (onValueChanged)="obtenerProvinciaSeleccionada($event)"
           formControlName="provincia" [searchEnabled]="true">
          <dx-validator>
            <dxi-validation-rule type="required" message="La provincia es requerida"></dxi-validation-rule>
          </dx-validator>
        </dx-select-box>
      </div>
    </div>

    <div class="dx-field">
      <div class="dx-field-label">Localidad</div>
      <div class="dx-field-value">
        <dx-select-box id="localidad" 
          [dataSource]="localidadesxProvincia" 
          displayExpr="Nombre" 
          valueExpr="IdLocalidad"
          validationMessagePosition="left" 
          [inputAttr]="{ 'aria-label': 'Localidad' }" 
          formControlName="localidad" 
          [searchEnabled]="true"
          placeholder="Para ver las opciones escribí 3 letras de tu localidad y presiona la tecla Enter, en caso de no encontrarla busca la localidad 'GENERICO'"
          [hint]="'Para ver las opciones escribí 3 letras de tu localidad y presiona la tecla Enter, en caso de no encontrarla busca la localidad GENERICO'"
          (onEnterKey)="obtenerLocalidadxProvincia($event)"  
          >
          <dx-validator>
            <dxi-validation-rule type="required" message="La localidad es requerida"></dxi-validation-rule>
          </dx-validator>
        </dx-select-box>
      </div>
    </div>


    <div class="dx-field">
      <div class="dx-field-label">Domicilio</div>
      <div class="dx-field-value">
        <dx-text-box validationMessagePosition="left" [inputAttr]="{ 'aria-label': 'Address' }"
          formControlName="domicilio">
          <dx-validator>
            <dxi-validation-rule type="required" message="El domicilio es requerido"></dxi-validation-rule>
          </dx-validator>
        </dx-text-box>
      </div>
    </div>

    <div class="dx-field">
      <div class="dx-field-label">Codigo Postal</div>
      <div class="dx-field-value">
        <dx-text-box validationMessagePosition="left" [inputAttr]="{ 'aria-label': 'Codigo Postal' }"
          formControlName="cp">
          <dx-validator>
            <dxi-validation-rule type="required" message="El codigo postal es requerido"></dxi-validation-rule>
          </dx-validator>
        </dx-text-box>
      </div>
    </div>

    <div class="dx-field">
      <div class="dx-field-label">Zona de entrega</div>
      <div class="dx-field-value">
        <dx-select-box [dataSource]="zonasDeEntrega" displayExpr="Nombre" valueExpr="Id"
          validationMessagePosition="left" [inputAttr]="{ 'aria-label': 'Zona de entrega' }"
          formControlName="zonaDeEntrega" [searchEnabled]="true">
          <dx-validator>
            <dxi-validation-rule type="required" message="La Zona de entrega es requerida"></dxi-validation-rule>
          </dx-validator>
        </dx-select-box>
      </div>
    </div>

    <div *ngIf="isCreate()">
      <dx-check-box class="direccion-entrega" id="check" [(value)]="checkboxEntrega_value"
        [elementAttr]="{ 'aria-label': 'Checked' }" text="Domicilio de entrega igual a domicilio de facturación"
        [iconSize]="15">
      </dx-check-box>
    </div>
  </div>

  <div class="dx-fieldset" *ngIf="!dirEntregaIgualDireccion() || isUpdate()">
    <div class="dx-fieldset-header">Direccion de entrega</div>
    <div class="dx-field">
      <div class="dx-field-label">Pais</div>
      <div class="dx-field-value">
        <dx-select-box id="paisEntrega" [dataSource]="paises" displayExpr="Nombre" valueExpr="IdPais" validationMessagePosition="left"
          [inputAttr]="{ 'aria-label': 'Pais Entrega' }" (onValueChanged)="obtenerProvinciaxPaisEntrega($event)"
          formControlName="paisEntrega" [searchEnabled]="true" >
          <dx-validator>
            <dxi-validation-rule type="required" message="El pais es requerido"></dxi-validation-rule>
          </dx-validator>
        </dx-select-box>
      </div>
    </div>
    <div class="dx-field">
      <div class="dx-field-label">Provincia</div>
      <div class="dx-field-value">
        <dx-select-box id="provinciaEntrega" [dataSource]="provinciasxPaisEntrega" displayExpr="Nombre" valueExpr="IdProvincia"
          validationMessagePosition="left" [inputAttr]="{ 'aria-label': 'Provincia Entrega' }"
          (onValueChanged)="obtenerLocalidadxProvinciaEntrega($event)" formControlName="provinciaEntrega"
          [searchEnabled]="true">
          <dx-validator>
            <dxi-validation-rule type="required" message="La provincia es requerida"></dxi-validation-rule>
          </dx-validator>
        </dx-select-box>
      </div>
    </div>

    <div class="dx-field">
      <div class="dx-field-label">Localidad</div>
      <div class="dx-field-value">
        <dx-select-box id="localidadEntrega" [dataSource]="localidadesxProvinciaEntrega" displayExpr="Nombre" valueExpr="IdLocalidad"
          validationMessagePosition="left" [inputAttr]="{ 'aria-label': 'Localidad Entrega' }"
          formControlName="localidadEntrega" [searchEnabled]="true" >
          <dx-validator>
            <dxi-validation-rule type="required" message="La localidad es requerida"></dxi-validation-rule>
          </dx-validator>
        </dx-select-box>
      </div>
    </div>


    <div class="dx-field">
      <div class="dx-field-label">Domicilio</div>
      <div class="dx-field-value">
        <dx-text-box validationMessagePosition="left" [inputAttr]="{ 'aria-label': 'Domicilio Entrega' }"
          formControlName="domicilioEntrega">
          <dx-validator>
            <dxi-validation-rule type="required" message="El domicilio de entrega es requerido"></dxi-validation-rule>
          </dx-validator>
        </dx-text-box>
      </div>
    </div>

    <div class="dx-field">
      <div class="dx-field-label">Codigo Postal</div>
      <div class="dx-field-value">
        <dx-text-box validationMessagePosition="left" [inputAttr]="{ 'aria-label': 'Codigo Postal Entrega' }"
          formControlName="cpEntrega" >
          <dx-validator>
            <dxi-validation-rule type="required"
              message="El codigo postal de entrega es requerido"></dxi-validation-rule>
          </dx-validator>
        </dx-text-box>
      </div>
    </div>



  </div>


















  <!-- <div class="dx-fieldset" *ngIf="dirEntregaIgualDireccion()">
    <div class="dx-fieldset-header">Direccion de entrega</div>
    <div class="dx-field">
      <div class="dx-field-label">Pais</div>
      <div class="dx-field-value">
        <dx-text-box id="paisEntrega" [readOnly]="true" formControlName="paisEntrega"></dx-text-box>
      </div>
    </div>
    <div class="dx-field">
      <div class="dx-field-label">Provincia</div>
      <div class="dx-field-value">
        <dx-select-box id="provinciaEntrega" [dataSource]="provinciasxPaisEntrega" displayExpr="Nombre" valueExpr="IdProvincia"
          validationMessagePosition="left" [inputAttr]="{ 'aria-label': 'Provincia Entrega' }"
          (onValueChanged)="obtenerLocalidadxProvinciaEntrega($event)" formControlName="provinciaEntrega"
          [searchEnabled]="true" [readOnly]="dirEntregaIgualDireccion()">
          <dx-validator>
            <dxi-validation-rule type="required" message="La provincia es requerida"></dxi-validation-rule>
          </dx-validator>
        </dx-select-box>
      </div>
    </div>

    <div class="dx-field">
      <div class="dx-field-label">Localidad</div>
      <div class="dx-field-value">
        <dx-select-box id="localidadEntrega" [dataSource]="localidadesxProvinciaEntrega" displayExpr="Nombre" valueExpr="IdLocalidad"
          validationMessagePosition="left" [inputAttr]="{ 'aria-label': 'Localidad Entrega' }"
          formControlName="localidadEntrega" [searchEnabled]="true" [readOnly]="dirEntregaIgualDireccion()">
          <dx-validator>
            <dxi-validation-rule type="required" message="La localidad es requerida"></dxi-validation-rule>
          </dx-validator>
        </dx-select-box>
      </div>
    </div>


    <div class="dx-field">
      <div class="dx-field-label">Domicilio</div>
      <div class="dx-field-value">
        <dx-text-box validationMessagePosition="left" [inputAttr]="{ 'aria-label': 'Domicilio Entrega' }"
          formControlName="domicilioEntrega" [readOnly]="dirEntregaIgualDireccion()">
          <dx-validator>
            <dxi-validation-rule type="required" message="El domicilio de entrega es requerido"></dxi-validation-rule>
          </dx-validator>
        </dx-text-box>
      </div>
    </div>

    <div class="dx-field">
      <div class="dx-field-label">Codigo Postal</div>
      <div class="dx-field-value">
        <dx-text-box validationMessagePosition="left" [inputAttr]="{ 'aria-label': 'Codigo Postal Entrega' }"
          formControlName="cpEntrega" [readOnly]="dirEntregaIgualDireccion()">
          <dx-validator>
            <dxi-validation-rule type="required"
              message="El codigo postal de entrega es requerido"></dxi-validation-rule>
          </dx-validator>
        </dx-text-box>
      </div>
    </div>



  </div> -->


  <!-- <div class="dx-field-value">
      <div>
        <dx-check-box
          id="check"
          [value]="false"
          text="I agree to the Terms and Conditions"
        >
          <dx-validator>
            <dxi-validation-rule
              type="compare"
              [comparisonTarget]="checkComparison"
              message="You must agree to the Terms and Conditions"
            ></dxi-validation-rule>
          </dx-validator>
        </dx-check-box>
      </div>
    </div> -->


  <div class="dx-fieldset">
    <dx-validation-summary id="summary"></dx-validation-summary>
  
    <dx-button width="100%"  id="button" [text]="textoBoton" type="success" [useSubmitBehavior]="true"   >
    </dx-button>
  </div>

</form>
