import { NgModule, Component, OnInit, ViewChild, Input, ChangeDetectorRef, OnChanges, SimpleChanges, OnDestroy, Output, EventEmitter } from '@angular/core';
import { CommonModule } from '@angular/common';

import CustomStore from 'devextreme/data/custom_store';
import { DxDataGridComponent, DxDataGridModule } from 'devextreme-angular';

import { Columna, Modulo } from './custom-grilla.model';
import { CustomGrillaService } from './custom-grilla.service';
import { RouterModule } from '@angular/router';
import { ReturnStatement } from '@angular/compiler';
import notify from 'devextreme/ui/notify';

 
@Component({
  selector: 'custom-grid',
  templateUrl: './custom-grilla.component.html',
  styleUrls: ['./custom-grilla.component.scss'],

})

export class CustomGrillaComponent implements OnInit,  OnChanges{

  @ViewChild(DxDataGridComponent, { static: false }) dataGrid: DxDataGridComponent | undefined;
  

  @Input() modulo!: Modulo;

  @Input() arrFunFiltros!:Function[];
  @Input() onEditorPreparing!:Function;
  @Input() arrFunSetCellValues!:Function[];
  @Input() dataArray?:any[];
  @Input() parametros?:any;
  @Input() grillaAlto?: number  ;

  @Output() selectedDataRow = new EventEmitter<any>();
  @Output() Totales=new EventEmitter<any>();
  @Output() focusDataRow = new EventEmitter<any>();
  
  filtros?:Array<any>=[];
  popupVisible: boolean = false;
  cargado: Promise<boolean>;
  modoEdicion: string='row';
  titulo: string = "";
  dataSourceLookups: any[] = [];
  dataSource: any;

  gridColumns: any[] = [];
  enterKeyActions: string[];
  enterKeyDirections: string[];
  editOnkeyPress: boolean;
  enterKeyAction: string;
  enterKeyDirection: string;
  selectedRowIndex: any;
  loadingVisible: boolean = false;
  mensajeLoading!: string;
  id: number = 0;
  // Dimensiones
  popupAncho: number = 750;
  popupAlto: number = 600;


  // Filtros, busquedas y agrupamiento de filas
  groupPanel: boolean = true;
  searchPanel: boolean = true;
  filterRow: boolean = true;
  headerFilter: boolean = true;
  allowUpdating:boolean=true;
  allowAdding:boolean=true;
  allowDeleting:boolean=true;

  exportExcel: boolean = true;
  newRowPosition: string = "last";
  conPermisosDeModificar: boolean=true  ; // tomar este dato del perfil del usuario
  refreshModes: string[];
  SelectMode?:string="multiple";
  refreshMode: string;
  keyExpr : any[] =[]; 
  //variable para los datos en caso que no deba ir a la base sino mostrar datos que recibe en un arreglo
 

  lookupsDataSource: any[] = [];
  popupPosition: { of: Window & typeof globalThis; at: string; my: string; offset: { y: number; }; };


  constructor(private servicio: CustomGrillaService, private ref: ChangeDetectorRef) {
    //quitar this.employees, es para ver por que no anda el combo

    //this.modulos = servicio.getModulos();
    this.enterKeyDirections = ['none', 'column', 'row'];
    this.enterKeyDirection = 'column';
    this.editOnkeyPress = true;
    this.enterKeyActions = ['startEdit', 'moveFocus'];
    this.enterKeyAction = 'moveFocus';

    this.refreshMode = 'reshape';
    this.refreshModes = ['full', 'reshape', 'repaint'];
    this.cargado = Promise.resolve(false); // Setting the Promise as resolved after I have the needed data
    this.popupPosition = {
      of: window, at: 'top', my: 'top', offset: { y: 10 },
    };
    if (!this.grillaAlto) {this.grillaAlto=450}
  }


  ngOnInit(): void {
    this.start(this.modulo);

  }

  ngOnChanges(changes :SimpleChanges): void {
    
    this.start(this.modulo);




  }
 
  onEditingStart(e: any) {
    // con esta sentencia se marca una celda como dirty para que sea enviada al backend para la modifiacion
    //this.dataGrid.instance.cellValue(rowIndex, dataField, value);
  };

 
  
  

  start(e: Modulo) {
    // Carga las propiedades de la grilla y los backends indicados en el elemento selecionado
   let totales=this.Totales;
    this.groupPanel = e.groupPanel;
    this.searchPanel = e.searchPanel;
    this.filterRow = e.filterRow;
    this.headerFilter = e.headerFilter;
    this.exportExcel = e.exportExcel;
    //this.id = e.ID  ;
    this.popupAlto = e.popupAlto;
    this.popupAncho = e.popupAncho;
    this.titulo = e.Nombre;
    
    this.allowUpdating =e.allowUpdating;
    this.allowAdding=e.allowAdding;
    this.allowDeleting=e.allowDeleting;
    this.SelectMode=e.selectMode;

    let backend = e.backend;
    let backendUpdate = e.backendUpdate;
    let backendInsert = e.backendInsert;
    let backendDelete = e.backendDelete;
    let servicio: any = this.servicio;
    this.modoEdicion=e.modoEdicion;

    // console.log(this.parametros);

    // tomo configuracion de las columnas de la grilla
    let auxColumns: Columna[] = e.columnas;
    if (e.filtrosGrilla!==undefined) {
      this.filtros=e.filtrosGrilla;
    }
    //por cada columna tomada del JSON y guardada en la variable auxColumns,
    //configuro el objeto de propiedades de columnas de la grilla que usara el HTML (gridColumns)
    this.gridColumns = [];
    for (let elemento of auxColumns) {

      //Por cada columna de la grilla, guardo las propiedades
      let columna = {
        caption: elemento.caption,
        dataField: elemento.nombre,
        visible: elemento.visible,
        dataType: elemento.tipoDato,
        width: elemento.ancho,
        allowSorting: true,
        allowEditing: elemento.esEditable,
        link: elemento.link,
        template: elemento.template,
        lookupDatasource: elemento.lookup.datasource,
        lookupValue: elemento.lookup.value,
        lookupName: elemento.lookup.name,
        funcionSetCellValues:elemento.lookup.funcionSetCellValues,
        funcionFiltro:elemento.lookup.funcionFiltro,
        lookupData: {} ,//datos del lookup en null
        keyExpr:elemento.keyExpr
      };
      
      // funcionSetCellValues:elemento.lookup.funcionSetCellValues===undefined?null:elemento.lookup.funcionSetCellValues,

      this.gridColumns.push(columna);
      if (elemento.lookup.datasource != '') {
        //una vez que tengo el componente del arreglo ,
        // si es que la columna es del tipo lookup
        //le meto los datos del lookup de manera asincronica para que no trabe el render
          let i: number;

          i = this.gridColumns.findIndex((x: any) => {
            return x.dataField == elemento.nombre
          });

          //this.gridColumns[i].lookupData = data;
          this.gridColumns[i].lookupData = this.crearDataSource(elemento, this.servicio);
          //concateno el filtro por cada columna que venga con campos filtrados

          // 
          if (this.gridColumns[i].funcionFiltro===undefined ){
                //si no hay dependencia cargo la tabla directamente en la funcionfiltro (esta asignada al dataSource del lookup)
                this.gridColumns[i].funcionFiltro = this.gridColumns[i].lookupData; 
          } else {
                //si hay dependencia, tomo del arreglo de funciones filtro (recibido por @Input) el correspondiente a esta columna, para eso el campo funcionFiltro del JSON debe ser igual al nombre de la funcion del arreglo. 
                //Tanto el arreglo como los filtros
                //deben ser definidos en el componente padre, es decir el componente que llama a la custom_grilla
                //usamos funciones de primer orden para inicializarla con el arreglo total de datos, y en la funcion interna se le debe mandar 
                //los filtros a usar y el registro de la grilla, esto se lo manda el DOM en el envento directamente.
                 
                let idx=this.arrFunFiltros.findIndex(x=>x.name ==this.gridColumns[i].funcionFiltro);  
                let filtro=this.arrFunFiltros[idx](this.gridColumns[i].lookupData.store,this.parametros);
                this.gridColumns[i].funcionFiltro =  filtro;
          }

          //Aca ponemos las funciones que deben ejecutarse durante la seleccion de un valor de la columna independiente
          if (this.gridColumns[i].funcionSetCellValues===undefined){
                //si tiene funcion setCell la pongo en el html, sino pongo null
                this.gridColumns[i].funcionSetCellValues =null; 
          } else {

                let fnSetCellValue=this.arrFunSetCellValues.find(x=>x.name ==this.gridColumns[i].funcionSetCellValues);  
                if(fnSetCellValue!==undefined) {
                  this.gridColumns[i].funcionSetCellValues =  fnSetCellValue;
                }   
          }

          //this.gridColumns[i].lookupData= 
          // Una vez que estan cargados los datos del combo permito la renderizacion del html
          // TODO: corregir esto para que e banque mas de un combo por tabla , ahora libera la
          // renderizacion con el primer combo
          this.cargado = Promise.resolve(true);

      } else {
        //si no tiene lookups que abra sin esperar nada
        this.cargado = Promise.resolve(true);
      }


    }
    const solokey= e.columnas.filter(x=>x.keyExpr).map(x=>x.nombre);
    this.keyExpr.push(solokey);
    // const key=e.columnas.filter(x=>x.keyExpr).map()
 

    if (backend!='') {

    //Cargar el datasource indicado en el elemento seleccionado
        this.dataSource = new CustomStore({
           key: this.gridColumns[0].dataField,
          //key:this.keyExpr,          
          load(loadOptions: any) {
            loadOptions.sort=[];
            // loadOptions.take=50;
            return servicio.sendRequest(backend, 'GET', '', loadOptions)
              .toPromise()
              .then((data: any) => {
                totales.emit(data.totalCount);
                return({ data: data.data, totalCount: data.totalCount });
                  })
              .catch((error: any) => { 
                //throw 'Error en la carga de datos' + error.message; 
                notify(error.error.message,"error",3000)
  
              });
          },
          insert: (values: any) => {
            return servicio.sendRequest(backendInsert, 'POST', {
              values: JSON.stringify(values),
            })
              .toPromise()
              .then((data: any) => ({
                data: data.data
              }))
//              .catch((error: any) => { throw 'Error insertando datos: ' + error.error.InnerException.InnerException.ExceptionMessage; });
              .catch((error: any) => { 
                notify(error.error.message,"error",3000)
  
              //  throw 'Error insertando datos: ' + error.message; 
              });

            },
          update: (key, values) => {
            this.ref.detectChanges();
            return servicio.sendRequest(backendUpdate, 'PUT', {
              key,
              values: JSON.stringify(values),
            }).toPromise()
              .then((data: any) => ({
                data: data.data
              }))
              //.catch((error: any) => { throw 'Error modificando datos: ' + error.error.InnerException.InnerException.ExceptionMessage; });
              .catch((error: any) => { 
                notify(error.error.message,"error",3000)
               });

            },
          remove: (key) => {
            return servicio.sendRequest(backendDelete, 'DELETE', {
              key
            })
              .toPromise()
              .catch((error: any) => { 
               // throw 'Error eliminando datos: ' +error.error.InnerException.InnerException.ExceptionMessage 
                notify(error.error.message,"error",3000)
              
              });
          },
        });
      }
      else {
        //carga la estructra que viene por parametro input()
        let arr=this.dataArray;
           // forma el key de la grilla como concatenacion de todos los campos para garantizar que sean unicos
          const solokey= e.columnas.filter(x=>x.keyExpr).map(x=>x.nombre)
          this.keyExpr.push(solokey);

 
        //this.dataSource=this.dataArray;
         
        // this.dataSource = new CustomStore({
        //   key: this.keyExpr,
        //   load(  ) {
        //     // loadOptions.take=50;
        //     return of(arr).toPromise()
        //     .then((data: any) => ({
        //       data: data  ,
        //       totalCount : data.length
        //     }));
        //   },
        //   insert: (values: any) => { return values},
        //   update: (key, values) => {return values},
        //   remove: (key) => {             
        //               return servicio.sendRequest(backendDelete,'DELETE',1)
        //     .toPromise()
        //     .catch((error: any) => { throw 'Error eliminando datos: ' +error.error.InnerException.InnerException.ExceptionMessage });
        //   },
        // });          
    

          this.dataSource =this.dataArray;
      };

    //con esta variable se setean los filtros con la que queremos que arranque la grilla,
    //soporta operadores and entre campos
 
 
    this.popupVisible = true;

  } 
  


  crearDataSource(columna: Columna, servicio: any): any  {

    let lookupDatasource = {
      store: new CustomStore({
        key: columna.nombre,
        loadMode: "raw",
        load() {
          return servicio.getTabla(columna.lookup.datasource)
              .toPromise()
              .then((data: any) => {

                  return data.data;

                }
              )
        }
      }),
      paginate: true,
      pageSize: 50,
      filter:''

    };

    return lookupDatasource;
  }

  getSelectedData(data: any) {
    this.selectedRowIndex = data.component.getRowIndexByKey(data.selectedRowKeys[0]);
  }
  onContentReady(e: any) {


    e.component.option("loadPanel.enabled", false);
  }

  onFocusedRowChanged(e: any){
    const rowData = e.row && e.row.data;

    if (rowData) {
      
      
    this.focusDataRow.emit(rowData) ;
    //console.log(rowData);
    }

  }
  onSelectionChanged(data: any) {
    this.selectedDataRow.emit(data.selectedRowsData);
    //console.log (data.selectedRowsData)
    //TODO: Ver por que mierda no actualiza los registros seleccionados luego de recargar la grilla...

    this.dataGrid?.selectionChange;
  }




  onContextMenuPreparing (e:any){
    console.log(e.component);
  }

  deleteRecords(e: any) {
alert ("borrado")
  }
   
  onSaving(e: any) {
    e.cancel = true;

  }

  showLoadPanel(tipo: boolean, mensaje: string = "") {
    this.loadingVisible = tipo;
    this.mensajeLoading = mensaje;
  }


  ngOnDestroy(): void {

    // Borro el array de CustomStores
    this.lookupsDataSource = [];
  }
}
 
@NgModule({
  imports: [
    CommonModule,
    DxDataGridModule,
    RouterModule
  ],
  declarations: [ CustomGrillaComponent ],
  exports: [ CustomGrillaComponent ],
  providers: [CustomGrillaService]
})
export class CustomGrillaModule { }


