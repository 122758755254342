import { CommonModule } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, NgModule } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { DxButtonModule } from 'devextreme-angular';
import { DxFormModule } from 'devextreme-angular/ui/form';
import { DxLoadIndicatorModule } from 'devextreme-angular/ui/load-indicator';
import notify from 'devextreme/ui/notify';
import { AuthService } from '../../services/auth/auth.service';


@Component({
  selector: 'app-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.scss']
})
export class LoginFormComponent {

  buttonOptions = {
    text: "Acceder",
    useSubmitBehavior: true,
    width: "100%",
    type: "default"
  };

  loading = false;
  formData: any = {};
  public errorLogin = false;

  constructor(private authService: AuthService, private router: Router) { }

  async onSubmit(e: Event) {
    e.preventDefault();
    const { email, password, rememberMe } = this.formData;
    this.loading = true;


    const result = this.authService.login({email, password}, rememberMe).subscribe(res => {
      console.log(res);
      //recuperar los datos del usuario, el tipo y el menu de opciones disponibles
    }, (err) => {
      this.errorLogin = true;
      this.loading = false;
      notify( err.status===500?'Error accediendo al server:'+err.error.Message:err.error.Message, 'error ', 5000);
    });
  }

  onCreateAccountClick = () => {
    this.router.navigate(['/create-account']);
  }

}

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    DxFormModule,
    DxLoadIndicatorModule,
    DxButtonModule
  ],
  declarations: [ LoginFormComponent ],
  exports: [ LoginFormComponent ]
})
export class LoginFormModule { }
