import { CommonModule } from '@angular/common';
import { Component, NgModule } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { DxFormModule } from 'devextreme-angular/ui/form';
import { DxLoadIndicatorModule } from 'devextreme-angular/ui/load-indicator';
import notify from 'devextreme/ui/notify';
import { IEmailReset } from '../../models/passReset.model';
import { AuthService } from '../../services/auth/auth.service';

const notificationText = 'We\'ve sent a link to reset your password. Check your inbox.';

@Component({
  selector: 'app-reset-password-form',
  templateUrl: './reset-password-form.component.html',
  styleUrls: ['./reset-password-form.component.scss']
})
export class ResetPasswordFormComponent {
  loading = false;
  formData: any = {};

  buttonOptions = {
    text: "Restablecer contraseña",
    useSubmitBehavior: true,
    width: "100%",
    type: "default"
  };

  constructor(private authService: AuthService, private router: Router) { }

  onSubmit(e: Event) {
    e.preventDefault();
    // const { email } = this.formData;
    const email: IEmailReset = this.formData;
    this.loading = true;

    this.authService.resetPassword(email).subscribe((rc: any) => {
      notify("Se envió un mail con un link para realizar el blanqueo de su clave", 'success', 3000);
      this.router.navigate(['/login-form']);
      this.loading = false;
    },
    (data: any) => {
      notify(data.error.Message, 'error', 3000);
      this.loading = false;

    });

  }
}
@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    DxFormModule,
    DxLoadIndicatorModule
  ],
  declarations: [ResetPasswordFormComponent],
  exports: [ResetPasswordFormComponent]
})
export class ResetPasswordFormModule { }
