import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AuthService } from "../../shared/services/auth/auth.service";
import { Observable } from "rxjs";


@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    constructor(private authService: AuthService) { }
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const currentUser = this.authService.getUser;
        if (currentUser != null && !!currentUser.JwtToken) {
            req = req.clone({
                setHeaders: {
                    "Authorization": `Bearer ${currentUser.JwtToken}`
                }
            });
        }
        return next.handle(req);
    }

}
