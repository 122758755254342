import { Component, NgModule, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AuthService } from '../../services/auth/auth.service';

import { UserPanelModule } from '../user-panel/user-panel.component';
import { DxButtonModule } from 'devextreme-angular/ui/button';
import { DxToolbarModule } from 'devextreme-angular/ui/toolbar';

import { Router } from '@angular/router';
import { IApiUserAuthenticated } from '../../interfaces/IApiUserAuthenticated';
@Component({
  selector: 'app-header',
  templateUrl: 'header.component.html',
  styleUrls: ['./header.component.scss']
})

export class HeaderComponent implements OnInit {
  @Output()
  menuToggle = new EventEmitter<boolean>();

  @Input()
  menuToggleEnabled = false;

  @Input()
  title!: string;

  user: IApiUserAuthenticated | null = {LifeTime: new Date('1970/01/01'), Id : 0, JwtToken: "", Email: "", FirstName: "",LastName:"", Role: "",RefreshToken:"",Created:new Date('1970/01/01'),IsVerified:false, IdPjf: 0,idListaPrecio:1,ListaExcluidos:true};

  userMenuItems = [
  {
    text: 'Logout',
    icon: 'runner',
    onClick: () => {
      this.authService.logout();
    }
  },
  {
    text: 'Modificar Datos',
    icon: 'card',
    onClick: () => {
      this.router.navigate(['/', 'update-account', this.user?.IdPjf]);
      // .then(nav => {
      //   console.log(nav)
      // }, err => {
      //   console.log("Error en navegacion", err)
      // });
    }
  }
];

  constructor(private authService: AuthService, private router: Router) { }

  ngOnInit() {
    this.user = this.authService.getUser;
  }

  toggleMenu = () => {
    this.menuToggle.emit();
  }
}

@NgModule({
  imports: [
    CommonModule,
    DxButtonModule,
    UserPanelModule,
    DxToolbarModule
  ],
  declarations: [ HeaderComponent ],
  exports: [ HeaderComponent ]
})
export class HeaderModule { }
