import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { WebApiBaseService } from '../../shared/web-api-base/web-api-base.service';
import { Observable } from 'rxjs';



export interface ProductionDay {
  dia: Date;
  maximo: number;
  pedido: number;
}


 

@Injectable()
export class InicioService extends WebApiBaseService  {

  constructor(private http: HttpClient, private route: Router) {
    super();

  }
    getProductosVentas(desde:Date,hasta:Date ): Observable<any[]> {
      let params = new HttpParams()
      .set('dateFrom', this.formatDate(desde))
      .set('dateTo', this.formatDate(hasta));
    return this.http.get<any[]>(this.baseUrl + 'Produccion/GetProduccionFecha', {params});
    
  }
  private formatDate(date: Date): string {
    return date.toISOString().split('T')[0]; // Formato YYYY-MM-DD
  }
  }


 