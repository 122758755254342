import { Component, OnInit } from '@angular/core';
import {  InicioService, ProductionDay } from './inicio.service';

  
 

@Component({
  selector: 'app-inicio',
  templateUrl: './inicio.component.html',
  styleUrls: ['./inicio.component.scss']
})
export class InicioComponent implements OnInit {
  datos: any[]=[];
  fechaDesde: Date | string | number;
  fechaHasta: Date | string | number;
constructor  (private  serv: InicioService){
  const today = new Date();
  this.fechaDesde = new Date(today); // Primer día del mes actual
  //this.fechaHasta = new Date(today.getFullYear(), today.getMonth() + 1, 0); // Último día del mes actual
  this.fechaHasta = new Date(today.getTime() + 30 * 24 * 60 * 60 * 1000); // 30 dias 
    }
  maxValue: number=0;

  ngOnInit() {
    this.actualizarDatos();
  }

   
  
  actualizarDatos() {
    if (this.fechaDesde && this.fechaHasta) {
      const desde = new Date(this.fechaDesde);
      const hasta = new Date(this.fechaHasta);
      this.serv.getProductosVentas(desde, hasta).subscribe({
        next: (data:any) => {
          this.datos = data;
        },
        error: (error:any) => {
          console.error('Error fetching produccion data', error);
        }
      });
    }
  }
  onFechaChange(){
    this.actualizarDatos();

  }
  dateFormatter = (value: number | Date): string => {
    const date = value instanceof Date ? value : new Date(value);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  }
  customizeLabel = (arg: any): string => {
    return this.dateFormatter(arg.value);
  }
  // onPointClick(e: any) {
  //   // Puedes implementar la lógica para manejar el clic en un punto del gráfico aquí
  //   console.log('Punto clickeado:', e.target);
  // }
 
}