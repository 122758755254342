<dx-scroll-view height="100%" width="100%" class="with-footer single-card">

  <div class="dx-card content">
    <div class="header">
      <div class="title">{{title}}</div>
      <div class="description">{{description}}</div>
    </div>
    <ng-content></ng-content>
  </div>
</dx-scroll-view>
