import { CommonModule } from '@angular/common';
import { Component, NgModule, OnInit } from '@angular/core';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { ValidationCallbackData } from 'devextreme/ui/validation_rules';
import { DxFormModule } from 'devextreme-angular/ui/form';
import { DxLoadIndicatorModule } from 'devextreme-angular/ui/load-indicator';
import notify from 'devextreme/ui/notify';
import { AuthService } from '../../services/auth/auth.service';
import { UserChangePassword } from '../../models/user-credentials';


@Component({
  selector: 'app-change-passsword-form',
  templateUrl: './change-password-form.component.html'
})
export class ChangePasswordFormComponent implements OnInit {
  loading = false;
  formData: any = {};
  recoveryCode: string = '';
  email: string = '';

  changeClaveDto: UserChangePassword = {} as UserChangePassword;

  constructor(private authService: AuthService, private router: Router, private route: ActivatedRoute) { }

  buttonOptions = {
    text: "Cambiar contraseña",
    useSubmitBehavior: true,
    width: "100%",
    type: "default"
  };

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      this.recoveryCode = params.get('recoveryCode') || '';
      this.email = params.get('email') || '';
      console.log(this.recoveryCode);
    });

  }



  async onSubmit(e: Event) {
    e.preventDefault();
    const { password } = this.formData;
    const {confirmedPassword}=this.formData;
    this.loading = true;

    // this.changeClaveDto.Email = this.email;
    this.changeClaveDto.Password = password;
    this.changeClaveDto.confirmedPassword=confirmedPassword;
    this.changeClaveDto.Token = this.recoveryCode;

    const result = await this.authService.changePassword(this.changeClaveDto).subscribe((rc: any) => {
      notify("El cambio de clave fue exitoso", 'success', 3000);
      this.router.navigate(['/login-form']);
      this.loading = false;
    },
    (data: any) => {
      notify(data.error, 'error', 3000);
      this.loading = false;

    });



  }

  confirmPassword = (e: ValidationCallbackData) => {
    return e.value === this.formData.password;
  }
}
@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    DxFormModule,
    DxLoadIndicatorModule
  ],
  declarations: [ ChangePasswordFormComponent ],
  exports: [ ChangePasswordFormComponent ]
})
export class ChangePasswordFormModule { }
