import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { from, Observable, of, scheduled } from 'rxjs';
import { Localidad } from '../../models/localidad.model';
import { Pais } from '../../models/pais.model';
import { CondicionFiscal, Personeria, Rol, TipoDocumento } from '../../models/pjf.model';
import { Provincia } from '../../models/provincia.model';
import { ZonaDeEntrega } from '../../models/zona-entrega.model';
import { WebApiBaseService } from '../../web-api-base/web-api-base.service';
import { trimTrailingNulls } from '@angular/compiler/src/render3/view/util';

@Injectable({
  providedIn: 'root'
})
export class UserService extends WebApiBaseService {


  roles: Rol[] = [
    {
      Id: 1,
      Nombre: "Personal de la Empresa"
    },
    {
      Id: 2,
      Nombre: "Cliente Mayorista"
    }

  ]

  personeria: Personeria[] = [
    {
      Id: "F",
      Nombre: "Persona Física"
    },
    {
      Id: "J",
      Nombre: "Persona Jurídica"
    },
  ]

  condFiscal: CondicionFiscal[] = [
    {
      Id: "CF",
      Nombre: "Consumidor final",
      Tipo: "CUIL"
    },
    {
      Id: "CF",
      Nombre: "Consumidor final",
      Tipo: "CUIT"
    },
    {
      Id: "RI",
      Nombre: "Responsable Inscripto",
      Tipo: "CUIT"
    },
    {
      Id: "MO",
      Nombre: "Monotributista",
      Tipo: "CUIT"
    },
  ]

  tiposDeDocumento: TipoDocumento[] = [
    {
      Id: "CUIT",
      Nombre: "Clave unica de identificacion tributaria"
    },
    {
      Id: "CUIL",
      Nombre: "Clave unica de identificacion laboral"
    }
  ]


  constructor(private http: HttpClient) {
    super();
  }

  validarEmail(email: string): Observable<any> {
    if (email) {
      return this.http.get<boolean>(`${this.baseUrl}Accounts/validarQueNoExistaEmailEnBase?email=${email}`);
      
     
    } else {
      // Email is null or undefined, return an Observable that emits a specific value
      // For instance, an empty array could indicate an invalid email
      return of(false); // or throw an error using: throwError('Invalid email input');
    }
  }


  validarCUIT(cuit: string): Observable<any> {
    if (cuit) {
      return this.http.get<boolean  >(`${this.baseUrl}Accounts/validarCUIT?CUIT=${cuit}`);
    } else {
      // Email is null or undefined, return an Observable that emits a specific value
      // For instance, an empty array could indicate an invalid email
      return of(false); // or throw an error using: throwError('Invalid email input');
    }
  }

  

  getRoles() {
    return this.http.get<any[]>(`${this.baseUrl}TipoRelacion/List`);

  }


  getPersoneria(): Observable<Personeria[]> {
    return of(this.personeria);
  }

  getCondicionFiscal() {
    return of(this.condFiscal);

  }
  getTipoDocumento() {
    return of(this.tiposDeDocumento);

  }

  getPaises() {
    return this.http.get<Pais[]>(`${this.baseUrl}Paises/List`);

  }

  getProvincias() {
    return this.http.get<Provincia[]>(`${this.baseUrl}Provincias/List`);

  }

  getLocalidades() {
    return this.http.get<Localidad[]>(`${this.baseUrl}Localidades/List`);

  }

  getZonasDeEntrega() {
    //return of(this.zonasDeEntrega);
    return this.http.get<any>(`${this.baseUrl}ZonasDeEntrega/Get`);

  }

}
