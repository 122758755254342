export const navigation = [
  {
    text: 'Usuarios',
    path: '/Usuarios',
    icon: 'user'
  },
  {
    text: 'Pedidos',
    path: '/Pedidos',
    icon: 'orderedlist'
  },
  {
    text: 'Productos',
    path: '/Productos',
    icon: 'product'
  },
  {
    text: 'Produccion',
    path: '/inicio',
    icon: 'home'
  },
  {
    text: 'Reportes',
    path: '/ProductosOrden',
    icon: 'file'
  },
  {
    text: 'Envios',
    path: '/Envio',
    icon: 'cart'
  },
  {
    text: 'Configuracion',
    path: '/Configuracion',
    icon: 'checklist'
  }
  
  

];

export const navigationCliente = [
  {
    text: 'Pedidos',
    path: '/Pedidos',
    icon: 'orderedlist'
  }
  
  

];
