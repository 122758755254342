<div class="date-pickers">
    <dx-date-box
      [(value)]="fechaDesde"
      [max]="fechaHasta"
      type="date"
      (onValueChanged)="onFechaChange()"
    ></dx-date-box>
    <dx-date-box
      [(value)]="fechaHasta"
      [min]="fechaDesde"
      type="date"
      (onValueChanged)="onFechaChange()"
    ></dx-date-box>
  </div>
  
  <dx-chart
    id="chart"
    title="Kilos de Yogurt Pedidos vs. Kilos de Producción"
    [dataSource]="datos"
  >
    <dxi-series valueField="Pedido" name="Pedido"></dxi-series>
    <dxi-series valueField="Maximo" name="Máximo"></dxi-series>
    
    <dxo-common-series-settings
      argumentField="Dia"
      type="bar"
      hoverMode="allArgumentPoints"
      selectionMode="allArgumentPoints">
      <dxo-label
        [visible]="true"
        [format]="{
          type: 'fixedPoint',
          precision: 0
        }">
      </dxo-label>
    </dxo-common-series-settings>
    
    <dxo-argument-axis>
        <dxo-label [customizeText]="customizeLabel">
        </dxo-label>
    </dxo-argument-axis>
    
    <dxo-legend
      verticalAlignment="bottom"
      horizontalAlignment="center">
    </dxo-legend>
    
    <dxo-export [enabled]="true"></dxo-export>
  </dx-chart>