<dx-data-grid
  #dataGrid
  [height]="grillaAlto"
  [dataSource]="dataSource"
  [selectedRowKeys]="[]"
  [keyExpr]="keyExpr"
  [rowAlternationEnabled]="true"
  [allowColumnResizing] = "true"
  [showBorders] = "true"
  [columnHidingEnabled] = "true"
  [focusedRowEnabled] = "true"
  [focusedRowIndex] = "0"
  [hoverStateEnabled] = "true" 
  [allowColumnReordering]="true"
  (onEditingStart) = "onEditingStart($event)"
  (onEditorPreparing)="onEditorPreparing($event)"
  (onSelectionChanged)="onSelectionChanged($event)"
  
  
  [remoteOperations] = "true"
  newRowPosition = "newRowPosition"  
  [filterValue]="filtros"
  (onFocusedRowChanged)="onFocusedRowChanged($event)"
  
  >
  

<dxo-selection
  showCheckBoxesMode="always"
  mode="SelectMode"
></dxo-selection>
<!-- <dxo-selection mode="multiple"></dxo-selection> -->


<!-- <dxo-filter-builder
[allowHierarchicalFields]="true"
>
</dxo-filter-builder> -->
<!-- <dxo-filter-builder-popup [position]="popupPosition">
</dxo-filter-builder-popup> -->

  <dxo-editing *ngIf="conPermisosDeModificar"
    [mode]="modoEdicion"
    [refreshMode]="refreshMode"
    [allowUpdating]="allowUpdating"
    [allowAdding]="allowAdding"
    [allowDeleting]="allowDeleting"
    [useIcons]="true"
    noDataText="No se encontraron datos"
  >
  </dxo-editing> 
  
  <!-- configuro las columnas con sus lookups -->
  <dxi-column *ngFor="let item of gridColumns"
  dataField="{{item.dataField}}"
  [width]="item.width"
  [visible]="item.visible"
  [allowEditing]="item.allowEditing"
  caption="{{item.caption}}"
  [cellTemplate]="item.template"
  [dataType]="item.dataType"
  >
  <!-- [setCellValue]="item.funcionSetCellValues" -->

  
  <dxo-lookup *ngIf="item.lookupDatasource!=''"
    
          [dataSource]="item.funcionFiltro"
          valueExpr="{{item.lookupValue}}"
          displayExpr="{{item.lookupName}}"
          pageLoadMode="nextButton"
          nextButtonText="Mas...">

    </dxo-lookup>

    <!-- Si quiero incluir un link en una columna-->
    <div *ngIf="item.link">
      <div *dxTemplate="let cell of 'linkTemplate'" >
        <!-- Utilizo un template externo para poder pasarle las variables que no se encuentran
             en el contexto -->

        <ng-container
          [ngTemplateOutlet]="linkItemTemplate"
          [ngTemplateOutletContext]="{ itemData: cell, extraData: item }">
        </ng-container>
      </div>
    </div>

    <!-- data: propiedades de la columna (column del Datagrid)
         extraData: estructura que define dinamicamente el formato de las columnas del datagrid
    -->
    <ng-template #linkItemTemplate let-data="itemData" let-extraData="extraData">
      
      <a class="dx-icon-add"  [routerLink]="[extraData.link[0], extraData.link[1], data.text]" routerLinkActive="router-link-active" ></a>  
     
    </ng-template>

  </dxi-column>



  <dxo-export [enabled]="exportExcel" [allowExportSelectedData]="true"  
  ></dxo-export>
  
  <dxo-load-panel [enabled]="true"></dxo-load-panel>


  <dxo-keyboard-navigation
    [editOnKeyPress]="editOnkeyPress"
    [enterKeyAction]="enterKeyAction"
    [enterKeyDirection]="enterKeyDirection"
  >
  </dxo-keyboard-navigation>

  <dxo-load-panel [enabled]="true" text="Cargando ..."></dxo-load-panel>
  <!-- <dxo-paging [enabled]="true"  ></dxo-paging>   -->
  <dxo-scrolling mode="virtual"  preloadEnabled="true" showScrollbar="always" [scrollByContent]="true"></dxo-scrolling>
  <dxo-paging [enabled]="true"  ></dxo-paging>  

   
  <dxi-sort-by-group-summary-info
  summaryItem="count"></dxi-sort-by-group-summary-info>

  <dxo-sorting mode="none"></dxo-sorting>

  <dxo-column-chooser [enabled]="true"></dxo-column-chooser>

  <dxo-search-panel
    [visible]="searchPanel"
    [highlightCaseSensitive]="true"
  >
  </dxo-search-panel>
  <dxo-group-panel [visible]="groupPanel"></dxo-group-panel>
  <dxo-grouping [autoExpandAll]="false"></dxo-grouping>


  <dxo-summary>
    <dxi-group-item  summaryType="count"> </dxi-group-item> 
    
  </dxo-summary>

  <!--   <dxo-summary>
    <dxi-total-item column="OrderNumber" summaryType="count"> </dxi-total-item>
    <dxi-total-item
      column="OrderDate"
      summaryType="min"
      [customizeText]="customizeDate"
    >
    </dxi-total-item>
    <dxi-total-item
      column="SaleAmount"
      summaryType="sum"
      valueFormat="currency"
    >
    </dxi-total-item>
  </dxo-summary> -->


  <dxo-header-filter [visible]="headerFilter"></dxo-header-filter>
  <dxo-filter-row [visible]="filterRow"></dxo-filter-row>

</dx-data-grid>
