import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginFormComponent, ResetPasswordFormComponent, CreateAccountFormComponent, ChangePasswordFormComponent } from './shared/components';
import { AuthGuard } from './core/guards/auth.guard';
import { NoAuthGuard } from './core/guards/no-auth.guard';
import { DxDataGridModule, DxFormModule } from 'devextreme-angular';
import { ValidateUserComponent } from './shared/components/validate-user/validate-user.component';


const routes: Routes = [
  {
    path: 'Pedidos',
    loadChildren: () => import('./pages/pedido/pedido.module').then(m => m.PedidoModule),
    canActivate: [ AuthGuard ]
  },

  {
    path: 'inicio',
    loadChildren: () => import('./pages/inicio/inicio.module').then(m => m.InicioModule),
    canActivate: [ AuthGuard ]
  },
  {
    path: 'Usuarios',
    loadChildren: () => import('./pages/asignacion-usuarios/asignacion-usuarios.module').then(m => m.AsignacionUsuariosModule),
    canActivate: [ AuthGuard ]
  },
  {
    path: 'Productos',
    loadChildren: () => import('./pages/productos/producto.module').then(m => m.ProductoModule),
    canActivate: [ AuthGuard ]
  },
 
  {
    path: 'ProductosOrden',
    loadChildren: () => import('./pages/productosOrden/productoOrden.module').then(m => m.ProductoOrdenModule),
    canActivate: [ AuthGuard ]
  },
  {
    path: 'Envio',
    loadChildren: () => import('./pages/envio/envio.module').then(m => m.EnvioModule),
    canActivate: [ AuthGuard ]
  },
  {
    path: 'Configuracion',
    loadChildren: () => import('./pages/configuracion/configuracion.module').then(m => m.configuracionModule),
    canActivate: [ AuthGuard ]
  },
  {
    path: 'update-account/:user',
    loadChildren: () => import('./pages/cuenta/update-account/update-account.module').then(m => m.UpdateAccountModule),
    canActivate: [ AuthGuard ]
  },
  {
    path: 'login-form',
    component: LoginFormComponent,
    canActivate: [ NoAuthGuard ]
  },
  {
    path: 'reset-password',
    component: ResetPasswordFormComponent,
    canActivate: [ NoAuthGuard ]
  },
  {
    path: 'create-account',
    component: CreateAccountFormComponent,
    canActivate: [ NoAuthGuard ]
  },
  {
    path: 'change-password/:email/:recoveryCode',
    component: ChangePasswordFormComponent,
    canActivate: [ NoAuthGuard ]
  },
  {
    path: 'validate-user/:recoveryCode',
    component: ValidateUserComponent,
    canActivate: [ NoAuthGuard ]
  },
  {
    path: '**',
    redirectTo: 'login-form',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { useHash: true }),
    DxDataGridModule,
    DxFormModule
  ],
  providers: [],
  exports: [RouterModule],
  declarations: []
})
export class AppRoutingModule { }
