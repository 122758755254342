import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { AuthService } from '../../shared/services/auth/auth.service';
import { Observable } from 'rxjs';
import { IApiUserAuthenticated } from 'src/app/shared/interfaces/IApiUserAuthenticated';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private authService: AuthService) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot)
    : Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    const user:IApiUserAuthenticated | null = this.authService.getUser;
    const isAuthForm = [
      'login-form',
      'reset-password',
      'create-account',
      'change-password/:recoveryCode'
    ].includes(route.routeConfig?.path || '/');

    if (!!user && user.JwtToken ) {

      return true;
    }
    else {
      this.router.navigate(['/login-form'], {
        queryParams: { returnUrl: state.url }
      });
      return false;
    }
  }

}
