import { CommonModule } from '@angular/common';
import { Component, NgModule, OnInit } from '@angular/core';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { DxFormModule } from 'devextreme-angular/ui/form';
import { DxLoadIndicatorModule } from 'devextreme-angular/ui/load-indicator';
import notify from 'devextreme/ui/notify';
import { AuthService } from '../../services/auth/auth.service';
import { UserChangePassword } from '../../models/user-credentials';


@Component({
  selector: 'app-validate-user',
  templateUrl: './validate-user.component.html'
})
export class ValidateUserComponent implements OnInit {
  loading = false;
  formData: any = {};
  recoveryCode: string = '';
  email: string = '';

  changeClaveDto: UserChangePassword;

  constructor(private authService: AuthService, private router: Router, private route: ActivatedRoute) {
    this.changeClaveDto = {} as UserChangePassword;
   }

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      this.recoveryCode = params.get('recoveryCode') || '';

      console.log(this.recoveryCode);
    });

    this.validarUsuario()
  }

  validarUsuario() {

    // this.changeClaveDto.Email = this.email;
    // this.changeClaveDto.Password = "noimporta";
    // this.changeClaveDto.Token = this.recoveryCode;

    // const result = await this.authService.validateUserEmail (this.changeClaveDto).subscribe((rc: any) => {
      console.log(`validateuser`);
 
    var datos = {Token: this.recoveryCode};
     this.authService.validateUserEmail(datos).subscribe((rc: any) => {
      notify("La cuenta fue validada exitosamente", 'success', 3000);
      this.router.navigate(['/login-form']);
      this.loading = false;
    },
    (data: any) => {
      notify(data.error, 'error', 3000);
      this.loading = false;

    });



  }


}
@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    DxFormModule,
    DxLoadIndicatorModule
  ],
  declarations: [ ValidateUserComponent ],
  exports: [ ValidateUserComponent ]
})
export class ValidateUserModule { }
