// auth.interceptor.ts
import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { AuthService } from '../../shared/services/auth/auth.service';
 
@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private lastActivity: number = Date.now();
  private readonly TIMEOUT_ADMIN = 30 * 60 * 1000;    // 30 minutos para administradores
  private readonly TIMEOUT_NORMAL = 5 * 60 * 1000;    // 5 minutos para usuarios normales
    
  private get currentTimeout(): number {
    const user = this.authService.getUser; // o donde almacenes el tipo de usuario
    const userType=user?.Role;
    return userType === 'Admin' ? this.TIMEOUT_ADMIN : this.TIMEOUT_NORMAL;
  }
  constructor(private router: Router,private authService:AuthService) {
    // Actualizar lastActivity en eventos de usuario
    ['click', 'mousemove', 'keypress', 'scroll', 'touchstart'].forEach(evt =>
      document.addEventListener(evt, () => {
        this.lastActivity = Date.now();
      })
    );
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Verificar tiempo de inactividad
    const tiempoInactivo = Date.now() - this.lastActivity;
    const timeout = this.currentTimeout;
    
    console.log('Tiempo inactivo:', tiempoInactivo / 1000, 'segundos');
    console.log('Timeout actual:', timeout / 1000, 'segundos');
    console.log('Usuario tipo:', this.authService.getUser?.Role);
   if (Date.now() - this.lastActivity > this.currentTimeout) {
      //Logout por inactividad
      this.authService.currentUser.next(null);
      this.authService.CleanUserLocalStorage();
      this.router.navigateByUrl('login');
      console.log('⚠️ Cerrando sesión por inactividad');


      return new Observable(); // Detener la petición
    }

    // Actualizar tiempo de última actividad
    this.lastActivity = Date.now();
    const fecha = new Date(this.lastActivity);

    // console.log('Fecha completa:', fecha.toLocaleString());      
    return next.handle(request);
  }
}