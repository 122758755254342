import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { WebApiBaseService } from '../../web-api-base/web-api-base.service'
import { EMPTY } from 'rxjs';



@Injectable()
export class CustomGrillaService extends WebApiBaseService {
  requests: string[] = [];
  dataSource: any;

  // customersData: any;

  // shippersData: any;

  gridColumns: any;

  constructor(private http: HttpClient) {
    super();
  }

  isNotEmpty(value: any): boolean {
    return value !== undefined && value !== null && value !== '';
  }


  getTabla(backend: string): Observable<any[]> {
    if (backend != '') {
      return this.http.get<any[]>(this.baseUrl + backend);
    }
    else {
      return EMPTY;
    }
  }

  sendRequest(url: string, method = 'GET', data: any = {}, loadOptions: any = {} ): any {
    //  this.logRequest(method, url, data);
    let params: HttpParams = new HttpParams();

    [
      'skip',
      'take',
      'requireTotalCount',
      'requireGroupCount',
      'filter',
      'totalSummary',
      'group',
      'groupSummary',
    ].forEach((i) => {
      if (i in loadOptions && this.isNotEmpty(loadOptions[i])) {
        params = params.set(i, JSON.stringify(loadOptions[i]));
      }
    });
    const httpParams = new HttpParams({ fromObject: data });
    const httpOptions = { withCredentials: false, body: httpParams };
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache'
    });
    const optionsPost = { headers: httpHeaders, method: 'post' };
    const optionsPut = { headers: httpHeaders, method: 'put' };
    const optionsDelete = { headers: httpHeaders, method: 'delete' };

    let result: any;

    switch (method) {
      case 'GET':
        result = this.http.get(this.baseUrl + url, { params });
        break;
      case 'PUT':
        result = this.http.put(this.baseUrl + url + data.key,data.values, optionsPut);
        break;
      case 'POST':
        result = this.http.post(this.baseUrl + url,data.values, optionsPost);
        break;
      case 'DELETE':
        let urlcompleto=this.baseUrl + url + (data.key===undefined?data:data.key);
        result = this.http.delete(urlcompleto, optionsDelete);
        break;
    }
    return result;

  }

  clearRequests() {
    this.requests = [];
  }
  // getEnterKeyActions() {
  //   return ['startEdit', 'moveFocus'];
  // }

  // getEnterKeyDirections() {
  //   return ['none', 'column', 'row'];
  // }
}
