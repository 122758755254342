import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InicioComponent } from './inicio.component';
import { InicioRoutingModule } from './inicio-routing.module';
import { InicioService } from './inicio.service';
import { DxChartModule, DxDateBoxModule } from 'devextreme-angular';




@NgModule({
  declarations: [
    InicioComponent
  ],
  imports: [
    CommonModule,
    InicioRoutingModule,
    DxChartModule,
    DxDateBoxModule,


  ],
  providers: [InicioService]

})
export class InicioModule { }
