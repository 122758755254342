import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { SideNavOuterToolbarModule, SideNavInnerToolbarModule, SingleCardModule } from './layouts';
import { FooterModule, ResetPasswordFormModule, CreateAccountFormModule, ChangePasswordFormModule, LoginFormModule } from './shared/components';
import { ScreenService, AppInfoService } from './shared/services';
import { DxButtonModule, DxTileViewModule } from 'devextreme-angular';
import { CoreModule } from './core/core.module';
import { AuthService } from './shared/services/auth/auth.service';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { JwtInterceptor } from './core/interceptors/jwt.interceptor';
import { SharedModule } from './shared/shared.module';
import { UnauthenticatedContentModule } from './unauthenticated-content';
import { NotAuthorizedContainerModule } from './not-authorized-container';


import { CustomGrillaModule } from './shared/components/custom-grilla/custom-grilla.component';
import { ValidateUserModule } from './shared/components/validate-user/validate-user.component';
import { InicioModule } from './pages/inicio/inicio.module';
import { ProgressComponent } from './shared/components/mensajes';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { AuthInterceptor } from './core/interceptors/auth.interceptor';


@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    DxButtonModule,
    CoreModule,
    SideNavOuterToolbarModule,
    SideNavInnerToolbarModule,
    SingleCardModule,
    FooterModule,
    ResetPasswordFormModule,
    CreateAccountFormModule,
    ChangePasswordFormModule,
    LoginFormModule,
    UnauthenticatedContentModule,
    SharedModule,
    DxTileViewModule,
    CustomGrillaModule,
    ValidateUserModule,
    InicioModule,
    SweetAlert2Module.forRoot()

  ],
  providers: [AuthService, ScreenService, AppInfoService,
  {
    provide: HTTP_INTERCEPTORS,
    useClass: JwtInterceptor,
    multi: true
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: AuthInterceptor,
    multi: true
  }],
  bootstrap: [AppComponent]
})
export class AppModule { }
