import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, NgModule, OnInit, Output, ViewChild } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { DxFormModule } from 'devextreme-angular/ui/form';
import { DxLoadIndicatorModule } from 'devextreme-angular/ui/load-indicator';
import { AbstractControl, AsyncValidatorFn, ReactiveFormsModule, ValidationErrors } from '@angular/forms';
import { FormControl, FormGroup, FormBuilder, Validators, Form } from '@angular/forms';

import {
  DxSelectBoxModule,
  DxCheckBoxModule,
  DxTextBoxModule,
  DxDateBoxModule,
  DxButtonModule,
  DxValidatorModule,
  DxValidatorComponent,
  DxValidationSummaryModule,
  DxSelectBoxComponent,
  DxTextBoxComponent,
  DxNumberBoxModule,
  DxNumberBoxComponent,
  DxAutocompleteModule,
} from 'devextreme-angular';

import notify from 'devextreme/ui/notify';
import { AuthService } from '../../services/auth/auth.service';
import { UserService } from '../../services/users/users.service';

import { UserCreation, UserModification, UserRegistration } from '../../models/user-credentials';
//import { Roles } from '../../services/users/roles-model';
import ArrayStore from 'devextreme/data/array_store';
import { Pais } from '../../models/pais.model';
import { Provincia } from '../../models/provincia.model';
import { Localidad } from '../../models/localidad.model';
import { ValueChangedEvent } from 'devextreme/ui/drop_down_box';
import { CondicionFiscal, Personeria, Pjf, Rol, TipoDocumento } from '../../models/pjf.model';
import { ZonaDeEntrega } from '../../models/zona-entrega.model';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import dxNumberBox from 'devextreme/ui/number_box';
import { isDefined } from '@angular/compiler/src/util';



@Component({
  selector: 'app-create-account-form',
  templateUrl: './create-account-form.component.html',
  styleUrls: ['./create-account-form.component.scss']
})

export class CreateAccountFormComponent implements OnInit {

  @ViewChild('targetValidator', { static: false }) validator: DxValidatorComponent | undefined;
  // @ViewChild('pais', { static: false }) pais: DxSelectBoxComponent | undefined;
  // @ViewChild('provincia', { static: false }) provincia: DxSelectBoxComponent | undefined;
  // @ViewChild('localidad', { static: false }) localidad: DxSelectBoxComponent | undefined;
  @ViewChild('paisEntrega', { static: false }) paisEntrega: DxTextBoxComponent | undefined;
  @ViewChild('provinciaEntrega', { static: false }) provinciaEntrega: DxSelectBoxComponent | undefined;
  @ViewChild('localidadEntrega', { static: false }) localidadEntrega: DxSelectBoxComponent | undefined;
  @ViewChild('codigoArea', { static: false }) codigoArea: DxNumberBoxComponent | undefined;
  @ViewChild('codigoPais', { static: false }) codigoPais: DxNumberBoxComponent | undefined;
  @ViewChild('telefono', { static: false }) telefono: DxTextBoxComponent | undefined;



  @Input() modo: string = 'create';
  @Input() cliente: any = {} as any;
  @Output() actualizado = new EventEmitter<UserModification>();

  registrationForm: FormGroup = {} as FormGroup;
  public mask: string = '';

  mensajeCuit='';
  mensajeEmail='';
  password = '';
  confirmPassword = '';
  passwordMode = 'password';
  confirmPasswordMode = 'password';
  maxDate: Date = new Date();
  cityPattern = '^[^0-9]+$';
  namePattern: any = /^[^0-9]+$/;
  // phonePattern: any = /^[02-9]\d{8}$/;
  phonePattern: any = /^[02-9]/;

  // Direccion
  paises: Pais[] = [];
  provincias: Provincia[] = [];
  provinciasxPais: Provincia[] = [];
  localidades: Localidad[] = [];
  localidadesxProvincia: Localidad[] = [];

  personeria: Personeria[] = [];
  roles: Rol[] = [];
  condicionFiscal: CondicionFiscal[] = [];
  condicionFiscalxTipoDoc: CondicionFiscal[] = [];
  tipoDocumento: TipoDocumento[] = [];

  // Direccion de entrega
  provinciasxPaisEntrega: Provincia[] = [];
  localidadesxProvinciaEntrega: Localidad[] = [];

  zonasDeEntrega: ZonaDeEntrega[] = [];

  checkboxEntrega_value: boolean | undefined | null = true;

  phoneRules: any = {
    X: /[02-9]/,
  };
  textoBoton: string = 'Registrarse';
  cargado: boolean = false;


  loading = false;
  formData: any = {};
  data: any;
  //roles: Roles[];
  rol: number = 2;

  idProvinciaSeleccionada:number=0;
 


  constructor(
    private authService: AuthService,
    private router: Router,
    private userSvc: UserService,
    private formBuilder: FormBuilder,
    private userService:UserService,
  ) {

    // Carga data en los combos
    // this.cargarLookups();

    // this.obtenerProvinciaxPais = this.obtenerProvinciaxPais.bind(this);
    // this.obtenerLocalidadxProvincia = this.obtenerLocalidadxProvincia.bind(this);
    this.obtenerLocalidadxProvinciaEntrega = this.obtenerLocalidadxProvinciaEntrega.bind(this);
    this.obtenerProvinciaxPaisEntrega = this.obtenerProvinciaxPaisEntrega.bind(this);
    // this.sendRequestCuit = this.sendRequestCuit.bind(this);
    // this.sendRequestEmail = this.sendRequestEmail.bind(this);
    

  }

  // ngAfterViewInit(): void {

  //   if (this.isUpdate()) {
  //     this.rellenarFormulario();
  //     this.textoBoton = 'Actualizar';
  //   }
  // }


  ngOnInit(): void {

   
  
    this.registrationForm = this.formBuilder.group({
      email: [''],
      password: ['', Validators.minLength(6)],
      confirmPassword: ['', Validators.required],
      razonSocial: [''],
      tipoDoc: [''],
      nroDoc: ['', [Validators.minLength(8)] ,  [this.asyncValidationCuit.bind(this)]],
      nombre: ['', Validators.required],
      apellido: ['', Validators.required],
      pais: ['', Validators.required],
      provincia: ['', Validators.required],
      localidad: ['', Validators.required],
      domicilio: ['', Validators.required],
      cp: ['', Validators.required],
      paisEntrega: [''],
      provinciaEntrega: [''],
      localidadEntrega: [''],
      domicilioEntrega: [''],
      cpEntrega: [''],
      codigoPais: [{ value: '+549', disabled: true }],
      codigoArea: ['', Validators.required],
      telefono: ['', Validators.required],
      personeria: [''],
      rol: [''],
      condicionFiscal: [''],
      zonaDeEntrega: ['', Validators.required],
      nombreFantasia: ['', Validators.required]


    });

    // Carga de lookups
    this.cargarLookups();
    const control = this.registrationForm.get('nroDoc');
    if (control) {
      control.statusChanges.subscribe(status => {
        if (status === 'VALID') {
          // El control es válido, puedes borrar el contenido del div aquí
          this.mensajeCuit="";
        }
      });
    }

  }


  
  onKeyDown(e: any) {
    const event = e.event;

    const str = event.key || String.fromCharCode(event.which);
    if ((this.codigoArea!.text.length > 0) && (str == 'Enter' || str == 'Tab')) {
      this.telefono!.value = ""
      switch (this.codigoArea?.text.length) {
        case 2:

          this.mask = '0000-0000';

          break;
        case 3:
          this.mask = '000-0000';

          break;
        case 4:
          this.mask = '00-0000';

          break;
        default:
          break;
      }
      if (str === 'Enter') {
        //si es tab no lo hago porque lo ahe solo
        this.telefono?.instance.focus();
      }
      return;

    }
  }
 



  asyncValidationEmail = (params: any) => {
    return new Promise((resolve, reject) => {
      this.userService.validarEmail(params.value).subscribe(
        res => {
          if (res===true) {
            this.mensajeEmail = "el Email "+params.value+" es unico en la base"; // No hay error, el CUIT es válido
            resolve(null);
          } else {
            this.mensajeEmail = "El Email "+params.value+" ya existe en la base"; // Mensaje de error personalizado
            reject({ 'customError': 'El valor '+params.value+' ya existe' });
          }
        },
        error => {
          console.error("El Email "+params.value+" ya existe en la base", error);
          this.mensajeEmail = "El Email "+params.value+" ya existe en la base"; // Mensaje para un error inesperado
          reject({ 'customError': 'El valor es inválido' });
        }
      );
    });
  };

   
 
  asyncValidationCuit = (params: any) => {
    
    return new Promise((resolve, reject) => {
      let msg;
      this.userService.validarCUIT(params.value).subscribe(
        res => {
          if (res.estado === 1) {
            this.mensajeCuit = res.message; // No hay error, el CUIT es válido
            resolve(null);
          } else {
            
            if (res) {
              this.mensajeCuit = ""; // Mensaje de error personalizado
              reject({ 'customError':  'Error al validar CUIT'  });
            } else{
              this.mensajeCuit =''; // Mensaje de error personalizado
              reject({ 'customError':  'Error al validar CUIT' });

            }
          }
        },
        error => {
          console.error('Error al validar CUIT:', error);
          this.mensajeCuit = ''; // Mensaje para un error inesperado
          reject({ 'customError': 'El valor es inválido' });
        }
      );
    });
  };
  //  sendRequestCUIT(control: AbstractControl): Promise<ValidationErrors | null> {
  //   return new Promise((resolve, reject) => {
  //     this.userService.validarCUIT(control.value).subscribe(
  //       res => {
  //         this.mensajeCuit = res.message; // Establece el mensaje para ambas ramas del condicional
  
  //         // Devuelve el objeto apropiado basado en el estado.
  //         if (res.estado == 0) {
  //           resolve({ cuitInexistente: true }); // Si el estado es 0, el CUIT no está tomado.
  //         } else {
  //           resolve(null);  // Si el estado no es 0, el CUIT está tomado.
  //         }
  //       },
  //       error => {
  //         console.error('HTTP error:', error);
  //         resolve({ cuitInexistente: true }); // Cambiado a 'true' si consideramos que un error HTTP implica la imposibilidad de tomar el CUIT.
  //       }
  //     );
  //   });
  // }
  

 

  // Completa los campos del formulario en caso de modificación
  private rellenarFormulario() {

    console.log("cliente: ", this.cliente);
    this.registrationForm.patchValue(
      {
        razonSocial: this.cliente?.Razonsocial,
        tipoDoc: this.cliente?.Tipodoc,
        nroDoc: this.cliente?.Nrodoc,
        nombre: this.cliente?.Account[0]?.FirstName,
        apellido: this.cliente?.Account[0]?.LastName,
        pais: this.cliente?.IdPais,
        provincia: this.cliente?.IdProvincia,
        localidad: this.cliente?.IdCiudad,
        domicilio: this.cliente?.Domicilio,
        cp: this.cliente?.Cp,
        paisEntrega: this.cliente?.IdPaisEntrega,
        provinciaEntrega: this.cliente?.IdProvinciaEntrega,
        localidadEntrega: this.cliente?.IdCiudadEntrega,
        domicilioEntrega: this.cliente?.DomicilioEntrega,
        cpEntrega: this.cliente?.CpEntrega,
        codigoPais: this.cliente?.Telefono.slice(0, 4),
        codigoArea: this.cliente?.Telefono.slice(4, 7),
        telefono: this.cliente?.Telefono.slice(7),
        personeria: this.cliente?.Personeria,
        rol: this.cliente?.IdTipoRelacion,
        condicionFiscal: this.cliente?.Condicioniva,
        zonaDeEntrega: this.cliente?.IdZonaEntrega,
        nombreFantasia: this.cliente?.Nombrefantasia

      });

  }

  private cargarLookups() {

    // Carga de lookups
    this.obtenerLookupsEncadenados().subscribe(() => {
      if (this.isUpdate()) {
        this.rellenarFormulario();
        this.textoBoton = 'Actualizar';
      }
    });

    this.obtenerRoles();
    this.obtenerPersoneria();
    this.obtenerCondicionFiscal();
    this.obtenerTipoDocumento();
    this.obtenerZonasDeEntrega();

  }


  private obtenerLookupsEncadenados(): Observable<any> {
    return this.userSvc.getPaises().pipe(
      switchMap((data: Pais[]) => {
        this.paises = data;
        return this.userSvc.getProvincias();
      }),
      switchMap((provincias: Provincia[]) => {
        this.provincias = provincias;
        return this.userSvc.getLocalidades();
      }),
      map((localidades: Localidad[]) => {
        this.localidades = localidades;
      })
    );
  }


  private obtenerTipoDocumento() {
    this.userSvc.getTipoDocumento().subscribe(data => {
      this.tipoDocumento = data;
    });
  }

  private obtenerCondicionFiscal() {

    this.userSvc.getCondicionFiscal().subscribe(data => {
      this.condicionFiscal = data;
    });

  }

  private obtenerPersoneria() {

    this.userSvc.getPersoneria().subscribe(data => {
      this.personeria = data;
    });

  }

  private obtenerPaises() {

    this.userSvc.getPaises().subscribe(data => {
      this.paises = data;
    });

  }

  private obtenerProvincias() {

    this.userSvc.getProvincias().subscribe(data => {
      this.provincias = data;
    });

  }

  private obtenerLocalidades() {

    this.userSvc.getLocalidades().subscribe(data => {
      this.localidades = data;
    });

  }

  private obtenerRoles() {

    this.userSvc.getRoles().subscribe((data: any) => {
      this.roles = data.data;
    });

  }

  private obtenerZonasDeEntrega() {

    this.userSvc.getZonasDeEntrega().subscribe(data => {
      this.zonasDeEntrega = data.data;
    });

  }


  passwordButton: any = {
    icon: 'assets/images/icons/eye.png',
    type: 'default',
    onClick: () => {
      this.passwordMode = this.passwordMode === 'text' ? 'password' : 'text';
    },
  };

  confirmPasswordButton: any = {
    icon: 'assets/images/icons/eye.png',
    type: 'default',
    onClick: () => {
      this.confirmPasswordMode = this.confirmPasswordMode === 'text' ? 'password' : 'text';
    },
  };


  dirEntregaIgualDireccion() {
    return this.checkboxEntrega_value === true;
  }



  obtenerProvinciaxPais(e: any) {
    let valor: number = 0;

    if (e.value === undefined) {
      valor = e;
    }
    else {
      valor = e.value;
    }

    //this.registrationForm.get('provincia')?.value



    this.provinciasxPais = this.provincias.filter(provincia => provincia.IdPais === valor);

    return this.provinciasxPais;

  }

  handleValueChange(event: any) {
    if (event.data && event.data.length >= 3) {
      this.obtenerLocalidadxProvincia(event);
    }
  }


  performSearch(searchText: string) {
    if(searchText==''){
      this.localidadesxProvincia = this.localidades.filter(item => item.IdProvincia === this.idProvinciaSeleccionada );
    } else {
      const regex = new RegExp(searchText, "i"); // "i" makes the search case-insensitive
      this.localidadesxProvincia = this.localidades.filter(item => item.IdProvincia === this.idProvinciaSeleccionada && regex.test(item.Nombre));
    }
  }

  obtenerLocalidadxProvincia(e: any) {

  this.performSearch(e.event.target.value ) ;

  }

 obtenerProvinciaSeleccionada(e:any){
  this.idProvinciaSeleccionada=e.value;
  this.localidadesxProvincia=[];
 }

  obtenerProvinciaxPaisEntrega(e: ValueChangedEvent) {
    this.provinciasxPaisEntrega = this.provincias.filter(provincia => provincia.IdPais === e.value);

    return this.provinciasxPaisEntrega;

  }


  obtenerLocalidadxProvinciaEntrega(e: ValueChangedEvent) {
    this.localidadesxProvinciaEntrega = this.localidades.filter(localidad => localidad.IdProvincia === e.value);
    return this.localidadesxProvinciaEntrega;

  }

  obtenerCondicionFiscalxTipoDoc(e: ValueChangedEvent) {
    this.condicionFiscalxTipoDoc = this.condicionFiscal.filter(cf => cf.Tipo === e.value);
    return this.condicionFiscalxTipoDoc;
  }

  onEnterKeyPressed() {

  }

  onSubmit(e: Event) {
    let aux;
    if (this.registrationForm.invalid) {
      const validationErrors = this.getFormValidationErrors();
      // Muestra el mensaje de error o haz algo con él
      console.log(validationErrors);
    }
    e.preventDefault();
    const userData: UserModification = this.registrationForm.value;

    // La direccion de entrega es la misma que la de facturacion (solo en la creacion de la cuenta)
    if (this.checkboxEntrega_value === true && this.isCreate()) {
      userData.paisEntrega = userData.pais;
      userData.provinciaEntrega = userData.provincia;
      userData.localidadEntrega = userData.localidad;
      userData.domicilioEntrega = userData.domicilio;
      userData.domicilioEntrega = userData.domicilio;
      userData.cpEntrega = userData.cp;
      
      aux = this.codigoPais!.text + this.codigoArea!.text + this.telefono!.text.replace(/-/g, '');
      userData.telefono = aux;
    }
    
    userData.personeria = 'F';
    this.loading = true;

    if (this.isUpdate()) {
      aux = this.codigoPais!.text + this.codigoArea!.text + this.telefono!.text.replace(/-/g, '');
      userData.telefono = aux;
      this.actualizado.emit(userData);
      return;
    }

    console.log(userData);
    const result = this.authService.crearCuenta(userData).subscribe(
      (data) => {
        this.loading = false;
        notify(data.message, 'Success', 10000);
        //notify("Cuenta creada correctamente, verifique su email para validar la misma y poder operar en el sistema", 'Exito', 2000);
        this.router.navigate(['/login-form']);
      },
      (data: any) => {
        let msg=this.getFormValidationErrors(); 

        msg +=" "+(data.error.message?data.error.message:"");
        notify("Se produjo un error al crear la cuenta: " + msg, 'Error', 5000);
        console.log(data.error);

        this.loading = false;
      });


  }


  getFormValidationErrors(): string {
    let errorMessages = '';

    Object.keys(this.registrationForm.controls).forEach(key => {
      const controlErrors = this.registrationForm.controls[key].errors;
      if (controlErrors !== null) {
        Object.keys(controlErrors).forEach(keyError => {
          // Aquí puedes personalizar el mensaje de error según el tipo de error
          errorMessages += `Error en ${key}: ${keyError}.\n`;
        });
      }
    });

    return errorMessages;
  }
  isUpdate() {
    return this.modo === 'update';
  }

  isCreate() {
    return this.modo === 'create';

  }


  onSelectValueChanged(e: any) {
    this.formData.rol = e.value;
  }


  passwordComparison = () => this.password;

  checkComparison() {
    return true;
  }

 

 
  onPasswordChanged() {
    if (this.confirmPassword) {
      this.validator?.instance.validate();
    }
  }

}
@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    DxFormModule,
    DxLoadIndicatorModule,
    DxSelectBoxModule,
    DxCheckBoxModule,
    DxTextBoxModule,
    DxDateBoxModule,
    DxButtonModule,
    DxValidatorModule,
    DxValidationSummaryModule,
    ReactiveFormsModule,
    DxNumberBoxModule,
    DxAutocompleteModule


  ],
  declarations: [CreateAccountFormComponent],
  exports: [CreateAccountFormComponent]
})
export class CreateAccountFormModule { }
